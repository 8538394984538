<template>
  <div v-if="selectedHorario">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-card>
        <b-row>
          <!-- TITULO Y SUBTITULO -->
          <b-col cols="6" class="mb-1">
            <h5 class="mb-0">
              {{getCurso.nombre}} {{getCurso.letra}}
            </h5>
            <p class="text-muted">
              Da en el botón Agregar hora, o haz click dentro del horario en la hora que deseas cargar.
              <!-- <br>
              A la derecha en "Cursos", podrás navegar por los horarios de los
              cursos que seleccionaste previamente. -->
            </p>
          </b-col>
          <b-col cols="12">
            <horarios-form
              :id_curso_selected="selectedHorario"
              @processForm="editar"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// ETIQUETAS
import { BOverlay, BCard, BCol, BRow } from 'bootstrap-vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// COMPONENTES HIJOS
import horariosForm from './components/HorariosForm.vue'

export default {
  components: {
    // ETIQUETAS
    BOverlay,
    BCard,
    BCol,
    BRow,

    // COMPONENTES HIJOS
    horariosForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('horarios', ['selectedHorario']),
    ...mapGetters({
      getCurso: 'cursos/getCurso',
    }),
  },
  mounted() {
    this.cargaAsignatura(this.selectedHorario)
  },
  methods: {
    ...mapActions({
      updateHorario: 'horarios/updateHorario',
      fetchCurso: 'cursos/fetchCurso',
    }),
    cargaAsignatura(curso) {
      this.fetchCurso(curso).then(() => {})
    },
    editar(horario) {
      this.spinner = true
      this.updateHorario(horario).then(() => {
        const errorhorarios = store.state.horarios
        const errorMessage = errorhorarios.errorMessage.errors
        if (!errorhorarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Horario editado 👍',
              text: `El horario "${horario.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'horarios',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorhorarios.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
